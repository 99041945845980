.chart-custom-fadeOut{
  opacity:0;
  transition: opacity 0.6s;
}
.chart-custom-fadeIn{
  opacity:1;
  transition: opacity 0.2s;
}
.chart-custom-slideInRight{
  right:0;
  transition: right 0.6s;
}

.chart-custom-slideOutRight{
  right: -100%;
  transition: right 0.4s;
}
