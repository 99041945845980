.filter-slideIn{
  right: 0;
  top:0;
  transition: right 0.6s;
}

.filter-slideOut{
  right: -100%;
  top:0;
  transition: right 0.4s;
}
