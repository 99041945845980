$primary: #2E3C77;
$primary100: #2E3C77;

$primaryDark: #202952;
$primaryLight: #3c4f9c;
$primaryLighter: #4155a8;

$secondary: #231f20;
$secondaryDark: #080707;
$secondaryLight: #3e3739;

$error: #D54D4C;
$errorDark: #cf3230;
$errorLight: #d85b5a;

$warning: #FF8F2C;
$warningDark: #ff7d0a;
$warningLight: #ffa14e;

$info: #2E3C77;
$infoDark: #2E3C77;
$infoLight: #2E3C77;

$success: #4CB263;
$successDark: #429a56;
$successLight: #63bd78;

$grey100: #d0d2d4;
$grey200: #E2E3E4;
$grey300: #EEEEEE;
$dark: #231f20;

$bg100: #F5F5F5;

// Text Colors
$textPrimary: #231f20;
$textSecondary: #4F4F4F;
$textDark: #757575;
$textHint: #BDBDBD;

// Text - Dark Color BGs
$textDarkPrimary: #fefefe;
$textDarkSecondary: #EEEEEE;
$textDarkDark: #fefefe;
$texDarkHint: #EEEEEE;

// Background Color
$background: #F5F5F5;
$backgroundGrey: #FBFBFB;
$backgroundDark: #231f20;

// Paper Color
$paper: #fefefe;
$paperDark: #231f20;

$menuHover: #EEEEEE;

// this will use in javascript
:export {
    primary: $primary;
    primaryDark: $primaryDark;
    primaryLight: $primaryLight;
    primaryLighter: $primaryLighter;
    primary100: $primary100;

    secondary: $secondary;
    secondaryDark: $secondaryDark;

    error: $error;
    errorDark: $errorDark;
    errorLight: $errorLight;

    warning: $warning;
    warningDark: $warningDark;
    warningLight: $warningLight;

    info: $info;
    infoDark: $infoDark;
    infoLight: $infoLight;

    success: $success;
    successDark: $successDark;
    successLight: $successLight;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;

    bg100: $bg100;

    // Text Colors
    textPrimary: $textPrimary;
    textSecondary: $textSecondary;
    textDark: $textDark;
    textHint: $textHint;

    // Text Dark Colors
    textDarkPrimary: $textDarkPrimary;
    textDarkSecondary: $textDarkSecondary;
    textDarkDark: $textDarkDark;
    textDarkHint: $texDarkHint;

    // Background Color
    background: $background;
    backgroundGrey: $backgroundGrey;
    backgroundDark: $backgroundDark;

    // Paper Color
    paper: $paper;
    paperDark: $paperDark;
    menuHover: $menuHover;
}
