.nav-custom-slideInRight{
  right:0;
  transition: right 0.6s;
}

.nav-custom-slideOutRight{
  right: -100%;
  transition: right 0.4s;
}


.nav-custom-slideInLeft{
  left:0;
  transition: left 0.6s;
}

.nav-custom-slideOutLeft{
  left: -100%;
  transition: left 0.4s;
}