.course-widget-custom-slideInRight{
  right:0;
  transition: right 0.3s;
}

.course-widget-custom-slideOutRight{
  right: -100%;
  transition: right 0.2s;
}

.course-widget-custom-fadeOut{
  opacity:0;
  transition: opacity 0.3s;
}
.course-widget-custom-fadeIn{
  opacity:1;
  transition: opacity 0.1s;
}
